<template>
    <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.write_off')">
        <custom-loader :show="showLoader">
            <CustomTable  ref="writeOffTable" :fields="fields" api-url="/api/client/v1/write_offs/">
                <template #buttons>
                    <b-button variant="primary" @click="startWriteOff">
                        {{ $t('material.write_off.add_write_off') }}
                    </b-button>
                </template>
                <template #state="{item}">
                    <div class="d-flex justify-content-center">
                        <b-badge variant="warning" v-if="item.editing_in_progress && item.confirmed">
                            {{ $t('general.yes') }}
                        </b-badge>
                        <b-badge variant="success" v-else-if="item.confirmed">
                            {{ $t('general.no') }}
                        </b-badge>
                        <b-badge variant="danger" v-else>
                            {{ $t('general.not_included_in_stock') }}
                        </b-badge>
                    </div>
                </template>
                <template #actions="{item}">
                    <div class="d-flex justify-content-center">
                        <b-button class="mr-1 m-0 button" variant="danger" size="sm" @click="remove(item)">
                            {{ $t('general.delete') }}
                        </b-button>
                        <b-button class="mr-1 m-0 button d-flex justify-content-center align-items-center" :variant="item.confirmed?'warning':'success'"  size="sm" style="width: 60px;" @click="editRow(item)">
                            <span v-if="item.confirmed"> {{ $t('general.edit') }}</span>
                            <span v-else> {{ $t('general.continue') }}</span>
                        </b-button>
                        <b-button class="m-0 button" variant="secondary"  size="sm" @click="viewWriteOffPrintout(item)" :disabled="!item.confirmed">
                            <span style="white-space: nowrap;">
                                {{ $t('general.view_printout') }}
                            </span>
                        </b-button>
                    </div>
                </template>
            </CustomTable>
            <report-preview-modal ref="writeOffReportModal" />
        </custom-loader>
    </b-card>
</template>

<script>
    import {BBadge, BButton, BCard } from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import ReportPreviewModal from '@/views/components/ReportPreviewModal.vue'
    import CustomLoader from '@/components/CustomLoader.vue'
    import CustomTable from '@/components/CustomTable.vue'
    import {Formaters} from '@/libs/formaters'

    export default {
        components: {CustomTable, BBadge, ReportPreviewModal, BCard, CustomLoader, BButton },
        data() {
            return {
                fields: [
                    {
                        key: 'write_off_code',
                        label: this.$t('table_fields.code'),
                        filter: true,
                        filter_type: 'input',
                        filterKey: 'write_off_code.w',
                        permissions: true,
                        min_width: 100
                    },
                    {
                        key: 'timestamp',
                        label: this.$t('table_fields.cash_register_created_at'),
                        filter: true,
                        permissions: true,
                        formatter: Formaters.formatDateOnly,
                        filter_type: 'date',
                        min_width: 75
                    },
                    {
                        key: 'warehouse_id',
                        label: this.$t('table_fields.warehouses'),
                        filter: true,
                        filter_type: 'select',
                        filterParamsName: 'name',
                        filterParams: this.warehouses,
                        reduce: ele => ele.id,
                        formatter: this.getWarehouseName,
                        filterFormatter: this.formatFilterPaymentType,
                        permissions: true,
                        min_width: 150
                    },
                    {
                        key: 'items',
                        label: this.$t('table_fields.write_off_items_num'),
                        filter: false,
                        filter_type: 'input',
                        formatter: this.getNumberOfItemsOnwriteOff,
                        filterFormatter: this.formatFilterPaymentType,
                        permissions: true,
                        min_width: 100
                    },
                    {
                        key: 'state',
                        label:  this.$t('table_fields.changes'),
                        permissions: true,
                        min_width: 160
                    },
                    {
                        key: 'actions',
                        label: this.$t('table_fields.actions'),
                        permissions: true
                    }
                ],
                businessUnitRooms: [],
                warehouses: [],
                showLoader: false,
                loaded: false,
                fileName: '',
                apiUrl: ''
            }
        },
        methods: {
            async viewWriteOffPrintout(data) {
                this.apiUrl = `/api/reports/v1/printout/write_off/${  data.id  }`
                this.fileName =  `Odpis_${data.write_off_code}.pdf`
                await this.$refs.writeOffReportModal.show(this.fileName, this.apiUrl)
            },
            getNumberOfItemsOnwriteOff(value) {
                if (value) {
                    return value.length
                }
                return '/'
            },
            async startWriteOff() {
                try {
                    const response = await this.$http.post('/api/client/v1/write_offs_temporary/create/000000000000000000000000')
                    const writeOffTemporary = response.data ?? []

                    await this.$router.push({name: 'edit_write_off', params:{id: writeOffTemporary.id}})
                } catch (error) {
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            async loadData() {
                try {
                    this.showLoader = true

                    const response1 = this.$http.get('/api/client/v1/business_units/')
                    const response2 = this.$http.get('/api/client/v1/warehouses/')

                    const response = await Promise.all([response1, response2])
                    this.businessUnitRooms = response[0].data ?? []
                    this.warehouses = response[1].data ?? []

                    const indexWarehouses = this.fields.findIndex(ele => ele.key === 'warehouse_id')

                    if (indexWarehouses >= 0) {
                        this.fields[indexWarehouses].filterParams = this.warehouses
                    }


                    this.loaded = true
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async viewTemporaryFile(data) {
                try {
                    const response = await this.$http.get(`/api/client/v1/write_offs/${data.id}`)
                    const writeOff = response.data ?? []

                    await this.$router.push({name: 'view_write_off', params:{id: writeOff.id}})
                } catch (error) {
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            async editRow(data) {
                try {
                    const response = await this.$http.post(`/api/client/v1/write_offs_temporary/create/${data.id}`)
                    const writeOffTemporary = response.data ?? []

                    await this.$router.push({name: 'edit_write_off', params:{id: writeOffTemporary.id}})
                } catch (error) {
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            async remove(data) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/write_offs/${data.id}`)

                    this.$printSuccess(this.$t('print.success.delete'))

                    if (this.$refs.writeOffTable) {
                        await this.$refs.writeOffTable.reloadData()
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            getWarehouseName(value) {
                if (value) {
                    const item = this.warehouses.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            },
            getDate(params) {
                if (params.data.date && params.data.time) {
                    return `${this.dayjs(params.data.date).format('DD.MM.YYYY')} ${this.dayjs(params.data.time).format('hh:mm')}`
                }
                return '/'
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>
.large-icon {
  width: 20px;  /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
}
</style>
